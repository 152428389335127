// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-all-tags-page-js": () => import("./../src/templates/all-tags-page.js" /* webpackChunkName: "component---src-templates-all-tags-page-js" */),
  "component---src-templates-special-layout-page-js": () => import("./../src/templates/special-layout-page.js" /* webpackChunkName: "component---src-templates-special-layout-page-js" */),
  "component---src-templates-standard-content-js": () => import("./../src/templates/standard-content.js" /* webpackChunkName: "component---src-templates-standard-content-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-en-js": () => import("./../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-book-thanks-en-js": () => import("./../src/pages/book-thanks.en.js" /* webpackChunkName: "component---src-pages-book-thanks-en-js" */),
  "component---src-pages-purchase-thanks-en-js": () => import("./../src/pages/purchase-thanks.en.js" /* webpackChunkName: "component---src-pages-purchase-thanks-en-js" */),
  "component---src-pages-qr-code-generator-js": () => import("./../src/pages/qr-code-generator.js" /* webpackChunkName: "component---src-pages-qr-code-generator-js" */),
  "component---src-pages-subscribe-thanks-en-js": () => import("./../src/pages/subscribe-thanks.en.js" /* webpackChunkName: "component---src-pages-subscribe-thanks-en-js" */),
  "component---src-pages-subscribe-thanks-es-js": () => import("./../src/pages/subscribe-thanks.es.js" /* webpackChunkName: "component---src-pages-subscribe-thanks-es-js" */),
  "component---src-pages-subscribe-thanks-fr-js": () => import("./../src/pages/subscribe-thanks.fr.js" /* webpackChunkName: "component---src-pages-subscribe-thanks-fr-js" */),
  "component---src-pages-thanks-en-js": () => import("./../src/pages/thanks.en.js" /* webpackChunkName: "component---src-pages-thanks-en-js" */),
  "component---src-pages-thanks-fr-js": () => import("./../src/pages/thanks.fr.js" /* webpackChunkName: "component---src-pages-thanks-fr-js" */),
  "component---src-pages-timezone-converter-page-js": () => import("./../src/pages/timezone-converter-page.js" /* webpackChunkName: "component---src-pages-timezone-converter-page-js" */),
  "component---src-pages-tycoon-js": () => import("./../src/pages/tycoon.js" /* webpackChunkName: "component---src-pages-tycoon-js" */),
  "component---src-pages-404-es-js": () => import("./../src/pages/404.es.js" /* webpackChunkName: "component---src-pages-404-es-js" */),
  "component---src-pages-404-fr-js": () => import("./../src/pages/404.fr.js" /* webpackChunkName: "component---src-pages-404-fr-js" */),
  "component---src-pages-trademarks-1-en-mdjx": () => import("./../src/pages/trademarks-1.en.mdjx" /* webpackChunkName: "component---src-pages-trademarks-1-en-mdjx" */)
}

